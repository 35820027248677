import React, { createContext, useContext } from 'react';

const AnalyticsTrackerContext = createContext(undefined);
AnalyticsTrackerContext.displayName = 'AnalyticsTrackerContext';

// eslint-disable-next-line react/prop-types
const AnalyticsTrackerProvider = ({ trackerDependencies, ...props }) => (
  <AnalyticsTrackerContext.Provider
    value={{
      tracker: {
        ...trackerDependencies
      }
    }}
    {...props}
  />
);

function assertNonNullable(
  val,
  error = null,
) {
  if (val === undefined || val === null) {
    const defaultMessage = 'Expected value to be defined and not null';
    throw error ?? new Error(defaultMessage);
  }
}

const useAnalyticsTracker = () => {
  const analyticsContext = useContext(AnalyticsTrackerContext);
  assertNonNullable(analyticsContext, new Error('useAnalyticsTracker must be used within an AnalyticsTrackerProvider'));
  const { tracker } = analyticsContext;
  const memoizedTracker = React.useMemo(() => {
    return tracker;
  }, [tracker]);
  return { tracker: memoizedTracker };
}

export { AnalyticsTrackerProvider, useAnalyticsTracker };
