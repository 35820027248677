export function setCookie(
  name,
  cookieValue = null,
  expiryDate,
  domain,
  sameSite
) {
  let value = Array.isArray(cookieValue)
    ? JSON.stringify(cookieValue)
    : cookieValue;

  value = value && escape(value);

  if (expiryDate instanceof Date) {
    value += `; expires=${expiryDate.toUTCString()}`;
  }

  if (typeof domain === 'string') {
    value += `; domain=${domain}`;
  }

  if (typeof sameSite === 'string') {
    value += `; SameSite=${sameSite}`;
  }

  value += '; path=/';

  document.cookie = `${name}=${value}`;
}

export function getCookie(name) {
  let value = null;
  const { cookie } = document;
  let cStart = cookie.indexOf(` ${name}=`);
  if (cStart === -1) {
    cStart = cookie.indexOf(`${name}=`);
  }
  if (cStart === -1) {
    value = null;
  } else {
    cStart = cookie.indexOf('=', cStart) + 1;
    let cEnd = cookie.indexOf(';', cStart);
    if (cEnd === -1) {
      cEnd = cookie.length;
    }
    value = unescape(cookie.substring(cStart, cEnd));
  }
  return value;
}

export function getCookieWithDefaultValue(
  name,
  defaultValue = ''
) {
  return getCookie(name) || defaultValue;
}

/**
 * Returns the value of a cookie which starts with the provided cookie name
 * @param {string} name
 */
export function getCookieBeginningWith(name) {
  const matches = document.cookie.match(`(^|[^;]+)\\s*${name}.*?=\\s*([^;]+)`);
  const cookie = matches && matches.pop();

  return cookie || '';
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
}

export function setCookieWithMidnightExpiration(name) {
  if (!getCookie(name)) {
    // Expire cookie at midnight
    const midnight = new Date();
    midnight.setHours(23, 59, 59, 0);
    setCookie(name, '1', midnight);
  }
}
