import axios from 'axios';
import humps from 'humps';
import { dataDogAddError } from '../utils/datadogAddError';

const configureApi = (token) => {
  axios.defaults.headers.common.Accept = 'application/json';
  if (token) {
    axios.defaults.headers.common.X_SESSION_KEY = token;
  }
  axios.interceptors.response.use(
    (response) => {
      // 🐍 => 🐫
      return {
        data: humps.camelizeKeys(response.data),
        error: response.error
      };
    },
    (error) => {
      const { method, url } = error.config;
      dataDogAddError(error, `Api error -  method: ${method}, url: ${url}`);

      return { error };
    }
  );
};

export default configureApi;
