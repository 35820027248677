import { oneflareAnalyticsDriverInstance } from './oneflareAnalytics';

/**
 * Load Segment Analytics
 * It calls the Segment Analytics library to load the analytics and replay the events to the platforms
 * @param writeKey - Segment write key
 * @returns void
 */
const loadSegmentAnalytics = (writeKey) => {
  oneflareAnalyticsDriverInstance.load(writeKey);
};

export { loadSegmentAnalytics };
