
import { deleteCookie, getCookieWithDefaultValue, setCookie } from './cookieUtils';
import { generate } from './anonIdGenerator';
import {
  anonymousIdCookieName,
  cookieMaxAgeInMilliseconds
} from './api';

class AnonymousUserIdClientAdapter {
  getId() {
    const userUUID = getCookieWithDefaultValue(anonymousIdCookieName);

    return userUUID || null;
  }

  setId(id) {
    setCookie(
      anonymousIdCookieName,
      id,
      new Date(Date.now() + cookieMaxAgeInMilliseconds)
    );
  }

  generateId() {
    return generate();
  }

  clearId() {
    deleteCookie(anonymousIdCookieName);
  }

  findOrCreateId({ onNotFound }) {
    let id = this.getId();

    if (!id || id === '') {
      id = this.generateId();
      this.setId(id);
      onNotFound();
    }

    return id;
  }
}

export { AnonymousUserIdClientAdapter };
