/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import React from 'react';
import ExecutionEnvironment from 'exenv';
import configureApi from 'shared/services/configureApi';
import { Provider } from 'react-redux';
import { ServerStyleSheet, ThemeProvider } from 'styled-components';
import { renderToString } from 'react-dom/server';
import oneflareTheme from '@oneflare/flarekit/lib/styles/styled-component/oneflare';
import { StaticRouter } from 'react-router-dom/server';
import { BrowserRouter } from 'react-router-dom';
import { loadSegmentAnalytics } from '../shared/utils/oneflareAnalytics/oneflareAnalyticsLoad';
import { oneflareAnalyticsDriverInstance } from '../shared/utils/oneflareAnalytics/oneflareAnalytics';
import { AnalyticsTrackerProvider } from '../shared/utils/oneflareAnalytics/analyticsProvider';
import { getAnalyticsTrackerClientDependencies } from '../shared/utils/oneflareAnalytics/analyticsTrackerDependencies';

const isServer = typeof window === 'undefined';

export default (component, store, basename, token = null) => {
  let analyticsTrackerDependencies;
  if (ExecutionEnvironment.canUseDOM) {
    // Load segment analytics
    const writeKey =  window?.analyticsConfig?.writeKey;
    loadSegmentAnalytics(writeKey);
    
    analyticsTrackerDependencies = getAnalyticsTrackerClientDependencies(window);

    // Identify user if userId is passed as a prop
    if (component.props.userId) {
      oneflareAnalyticsDriverInstance.identify(component.props.userId);
    }
  }

  const App = (
    <ThemeProvider theme={oneflareTheme}>
      <AnalyticsTrackerProvider trackerDependencies={analyticsTrackerDependencies}>
      {isServer ? (
        <StaticRouter>
          {store ? <Provider store={store}>{component}</Provider> : component}
        </StaticRouter>
      ) : store
        ? (
          <BrowserRouter basename={basename}>
            <Provider store={store}>{component}</Provider>
          </BrowserRouter>
        ) : component}
      </AnalyticsTrackerProvider>
    </ThemeProvider>
  );

  if (ExecutionEnvironment.canUseDOM) {
    // If you pass authenticationToken as a prop, axios will be configured
    // with the correct header
    configureApi(token || component.props.authenticationToken);
    return () => App;
  }

  const sheet = new ServerStyleSheet();
  try {
    const html = renderToString(sheet.collectStyles(App));
    const css = sheet.getStyleTags();

    return {
      renderedHtml: {
        componentHtml: html,
        componentCss: css
      }
    };
  } catch (error) {
    console.error('Error during server-side rendering:', error);
  } finally {
    sheet.seal();
  }
};
