import { AnonymousUserIdClientAdapter } from '../anonymousIdClientAdapter';
import { getCookieWithDefaultValue, setCookie } from '../anonymousIdClientAdapter/cookieUtils';
import { dataDogAddError } from '../datadogAddError';

// maps from cookie names to segment properties
const segmentCookieName = 'ajs_anonymous_id';
const segmentCookieDomain = 'oneflare.com';
const segmentCookieExpiryMillis = 1 * 24 * 60 * 60 * 1000; // 1 day

/**
 * Oneflare Analytics class
 * This class is used to track events and page views using Segment Analytics
 *
 * See: https://segment.com/docs/connections/spec/ for more methods and options to extend this class
 */
class OneflareAnalyticsDriver {
  constructor(segmentSdk, anonUserIdProvider) {
    this.segmentSdk = segmentSdk;
    this.anonUserIdProvider = anonUserIdProvider;
  }

  load(writeKey) {
    const { segmentSdk } = this;

    // Call sync as soon as analytics is fully loaded
    segmentSdk.analytics?.ready(() => {
      this.syncAnyonymousId();
    });

    segmentSdk.analytics?.load(writeKey);
  }

  identify(userId) {
    const { analytics } = this.segmentSdk;

    this.syncAnyonymousId();
    this.clearCachedUserTraits();

    if (analytics) {
      if (userId) {
        analytics.identify(userId);
      }
    } else {
      console.error('Segment analytics was not loaded');
    }
  }

  track(mappedData) {
    const { event_name: eventName, timezone, ...properties } = mappedData;
    const { segmentSdk } = this;
    const customProperties = {
      ...properties
    };

    const options = {};

    options.context = {
      ip: '0.0.0.0',
      timezone
    };

    options.integrations = {};
    if (mappedData.conversion_event) {
      options.integrations = { All: true };
    }

    this.syncAnyonymousId();
    segmentSdk.analytics?.track(eventName, customProperties, options);
  }

  page() {
    const { segmentSdk, syncAnyonymousId } = this;
    syncAnyonymousId();
    segmentSdk.analytics?.page();
  }

  isSegmentAnalyticsJsLoaded() {
    // The user function is not available before the analytics.js library is loaded
    return typeof this.segmentSdk.analytics?.user === 'function';
  }

  syncAnyonymousId() {
    try {
      let oneflareAnonId = this.anonUserIdProvider.getId();
      const segmentAnonId = this.getSegmentAnonId();

      if (!oneflareAnonId) {
        oneflareAnonId = this.anonUserIdProvider.generateId();
        console.info(
          `oneflare anonId is not available, using generated id '${oneflareAnonId}'`
        );
        this.anonUserIdProvider.setId(oneflareAnonId);
      }
  
      if (segmentAnonId !== oneflareAnonId) {
        console.debug(
          `syncing segmentAnonId '${segmentAnonId}' to oneflare anonId '${oneflareAnonId}'`
        );
        this.setAnonymousId(oneflareAnonId);
      }
    } catch (error) {
      dataDogAddError(error, 'Oneflare | Site | AnalyticsUserManager | syncAnyonymousId');
    }
  }

  setAnonymousId(anonymousId) {
    try {
      if (this.isSegmentAnalyticsJsLoaded()) {
        const { segmentSdk } = this;
        segmentSdk.analytics?.user().anonymousId(anonymousId);
      } else {
        console.debug(
          'segment analytics sdk has not loaded, setting the segment cookie directly'
        );
        setCookie(
          segmentCookieName,
          JSON.stringify(anonymousId),
          new Date(Date.now() + segmentCookieExpiryMillis),
          segmentCookieDomain
        );
      }
    } catch (error) {
      console.error('Error setting anonymous ID', error);
    }
  }

  getSegmentAnonId() {
    if (this.isSegmentAnalyticsJsLoaded()) {
      return this.segmentSdk.analytics?.user().anonymousId() ?? '';
    }
    console.debug(
      'segment analytics sdk has not loaded, accessing the segment cookie directly'
    );
    const segmentCookie = getCookieWithDefaultValue(segmentCookieName);
    try {
      return JSON.parse(segmentCookie);
    } catch (error) {
      console.info('Analytics 2.0 cookie detected');
      return segmentCookie ?? '';
    }
  }

  clearCachedUserTraits() {
    const { segmentSdk } = this;
    segmentSdk.localStorage?.removeItem('ajs_user_traits');
  }
}

const segmentSdk = {
  // Using a getter to avoid holding onto stale references of the analytics object
  // since this can be mutated elsewhere in the code
  get analytics() {
    return typeof window !== 'undefined' ? window.analytics : undefined;
  },
  get localStorage() {
    return typeof window !== 'undefined' ? window.localStorage : undefined;
  }
};

const anonUserIdProvider = new AnonymousUserIdClientAdapter();
const oneflareAnalyticsDriverInstance = new OneflareAnalyticsDriver(
  segmentSdk,
  anonUserIdProvider
);

export { OneflareAnalyticsDriver, oneflareAnalyticsDriverInstance };
