export const dataDogAddError = (error, message) => {
  if (window && window.DD_RUM && window.DD_RUM.addError) {
    const { DD_RUM: { addError } } = window;

    if (typeof error === 'string') {
      addError(new Error(error));
    } else {
      try {
        const newErrorObjectWithErrorSourceAsNewProperty = Object.assign({}, error, { errorSource: message });
        addError(error, newErrorObjectWithErrorSourceAsNewProperty);
      } catch {
        addError(error);
      }
    }
  }
}
