import { oneflareAnalyticsDriverInstance } from './oneflareAnalytics';

const AU_REGION_CODE = '1036';
const getAnalyticsTrackerClientDependencies = (
  window
) => ({
  getPageData: () => ({
    path: window.location.pathname,
    referrer: window.document.referrer,
    search: window.location.search,
    title: window.document.title,
    url: `${window.location.origin}${window.location.pathname}`
  }),
  getRegionCode: () => AU_REGION_CODE,
  getTimeZone: () => window.Intl.DateTimeFormat().resolvedOptions().timeZone,
  track: (mappedData) =>
    oneflareAnalyticsDriverInstance.track({
      ...mappedData
    }),
  page: () => {
    oneflareAnalyticsDriverInstance.page();
  }
});

export { getAnalyticsTrackerClientDependencies, AU_REGION_CODE };
